import { Component } from "react";
import { Nav } from 'react-bootstrap'
// LinkContainer must be use to have react-router-dom to fire a componentWillUnmount
import { LinkContainer } from 'react-router-bootstrap'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import AuthService from "../../services/authService";
import Card from "./Card";
import '../../styles/Auth.css'

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);

    this.state = {
      email: "",
      password: "",
      loading: false,
      message: ""
    };
  }

  validationSchema() {
    return Yup.object().shape({
      email: Yup.string().required("This field is required!"),
      password: Yup.string().required("This field is required!"),
    });
  }

  handleLogin(formValue) {
    const { email, password } = formValue;
    this.setState({
      message: "",
      loading: true
    });

    AuthService.login(email, password)
      .then((user) => {
        this.props.navigate("/");
        this.props.updateUser(user);
        //  window.location.reload();

      },
        error => {
          const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          this.setState({
            loading: false,
            message: resMessage
          });

        });
  }

  render() {
    const { loading, message } = this.state;
    const initialValues = {
      email: "",
      password: "",
    };

    return (
      <Card>

        <Formik initialValues={initialValues} validationSchema={this.validationSchema} onSubmit={this.handleLogin}>
          <Form>
            <div className="form-group">
              <label htmlFor="username">Email Address</label>
              <Field type="text" className="form-control" name="email" placeholder="" />
              <ErrorMessage name="email" component="div" className="alert alert-danger" />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Field type="password" className="form-control" name="password" placeholder="" />
              <ErrorMessage name="password" component="div" className="alert alert-danger" />
            </div>
            <LinkContainer to="/recover">
              <Nav.Link className="no-padding-left">Forgot Password?</Nav.Link>
            </LinkContainer>


            <hr />
            <div className="d-grid gap-2 col-6 mx-auto">

              <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"> </span>
                )}
                <span>Login</span>
              </button>
            </div>
            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              </div>
            )}

          </Form>
        </Formik>
      </Card>
    );
  }

}

