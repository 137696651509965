import { useEffect } from 'react';
import { Accordion, ListGroup } from 'react-bootstrap'
import "../styles/TreeView.css"


/**
 * 
 * @param {object} props
 * @param {object} props.diagrams List of all the diagram
 * @param {any} props.id id of the parent diagram or array for multiple parent, NULL for all the diagrams
 * @param {boolean} props.accordion Show as an accordion
 * @param {Function} props.onCount Function to return the count of item found
 * 
 */
function TreeView(props) {
  let count = 0;
  const nest = (items, id, link = 'id') =>
    items
      .filter(item => id.includes(item[link]))
      .map(item => {
        count = count + 1;
        return ({ ...item, children: nest(items, [item.id], 'diagramId') })
      });

  var id = [];
  if (!props.id) {
    id = props.diagrams
      .filter(item => !item.diagramId)
      .map(item => { return item.id })
  }
  else {
    id = Array.isArray(props.id) ? props.id : [props.id]
  }

  const tree = nest(props.diagrams, id, 'id')

  useEffect(() => {
    props.onCount(count)
  }, [props, count])


  function renderAccordionView(children) {
    return (
      <div>
        {children.map(node => {
          return (
            <Accordion.Item eventKey={node.id} disabled={node.children.length === 0}>
              <Accordion.Header>{node.name}</Accordion.Header>
              {node.children.length > 0 && <Accordion.Body> {renderAccordionView(node.children)} </Accordion.Body>}

            </Accordion.Item>)
        })}
      </div>
    )
  }

  function renderTreeView(children) {
    return (
      <ListGroup>
        {children.map(node => {
          return (
            <ListGroup.Item key={node.id} className={'level' + node.level}>
              {node.name}
              {node.children.length > 0 && renderTreeView(node.children)}
            </ListGroup.Item>
          )
        }
        )}
      </ListGroup>
    )
  }

  return (
    <div>
      {props.accordion ?
        <Accordion alwaysOpen>
          {renderAccordionView(tree)}
        </Accordion>
        :
        <div>
          {renderTreeView(tree)}
        </div>
      }
    </div>
  )
}

export default TreeView