import { Navbar, Nav, Col, NavDropdown } from 'react-bootstrap'
// LinkContainer must be use to have react-router-dom to fire a componentWillUnmount
import { LinkContainer } from 'react-router-bootstrap'
import logo from '../assets/logo.png'
import "../styles/Banner.css"


function Banner(props) {
  const title = 'ISA Factory'
  return (
    //<Navbar fixed="top" bg="light" variant="light"></Navbar>
    <Col>
      <Navbar collapseOnSelect fixed="top" expand="lg" bg="light" variant="light" >

        <LinkContainer to="/">
          <Navbar.Brand className="px-3">
            <img
              alt="ISA Factory"
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-center"
            />{' '}
            <Navbar.Text className="d-none d-sm-inline">{title}</Navbar.Text>
          </Navbar.Brand>
        </LinkContainer>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {props.user &&
              <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>}
            {!props.user && false && <a href="https://otusinnovations.ca/isa-factory/" target="_blank" rel="noopener noreferrer">Documentation</a>}
          </Nav>

          {!props.user ?
            <Nav>

              <LinkContainer to="/login">
                <Nav.Link>Login</Nav.Link>
              </LinkContainer>

              <LinkContainer to="/register">
                <Nav.Link>Sign Up</Nav.Link>
              </LinkContainer>

            </Nav>
            :
            <NavDropdown title={props.user.name || props.user.email} id="collasible-nav-dropdown">

              <LinkContainer to="/profile">
                <NavDropdown.Item>Profile</NavDropdown.Item>
              </LinkContainer>

              <LinkContainer to="/">
                <NavDropdown.Item onClick={() => { props.updateUser() }}>Logout</NavDropdown.Item>
              </LinkContainer>

            </NavDropdown>
          }
        </Navbar.Collapse>
      </Navbar>

    </Col>
  )
};

export default Banner