import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import TreeView from './TreeView';

/**
 * 
 * @param {object} props 
 * @param {boolean} props.show Set the visibility of the Modal
 * @param {Function} props.onCancel A callback fired when either the Cancel button is clicked or the escape key is pressed
 * @param {Function} props.onDelete A callback fired when either the Delete button is clicked or the enter key is pressed
 * @param {object} props.diagrams List of all the diagrams
 * @param {integer} props.id id of the parent diagram or array for multiple parent, NULL for all the diagrams
 * 
 */
function DeleteModal(props) {

  const [count, setCount] = useState(0);
  const handleCount = (count) => {
    setCount(count);
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onCancel}
      backdrop="static"
      keyboard={true}
    >
      <Modal.Header closeButton>
        
        <Modal.Title>Attention !</Modal.Title>
      
      </Modal.Header>
      
      <Modal.Body>

        <h5>You are about to delete {count} diagram(s). this step in not reversible.</h5>
        
          <TreeView 
            diagrams={props.diagrams}
            id={props.id}
            accordion={false} 
            onCount={handleCount}
          />
        

      </Modal.Body>
      
      <Modal.Footer>
        
        <Button variant="secondary" onClick={props.onCancel}>
          Cancel
        </Button>

        <Button variant="danger" onClick={props.onDelete}>
          Delete
        </Button>

      </Modal.Footer>

    </Modal>
  )
}

export default DeleteModal