import axios from 'axios';
import authHeader from './auth-header';
const API_URL = process.env.REACT_APP_API_URL + 'operational-location/';

class OpLocService {

  async createOpLoc(name = 'untitled', description) {
    const response = await axios.post(API_URL, {
      name : name,
      description: description,
    },{ headers: authHeader() });
      return response.data;
  }

  async listOpLoc(projectId, opLocId = null) {
    const response = await axios.get(API_URL, {
      params: { projectId: projectId, operationalLocationId: opLocId },
      headers: authHeader()
    });
    return response.data;
  }
   
  async readOpLoc(id) {
    const response = await axios.get(API_URL + id, { headers: authHeader() });
    return response.data;
  }
  
  async updateOpLoc(opLoc) {
    const response = await axios.put(API_URL + opLoc.id, opLoc,
    { headers: authHeader() });
      return response;
  }
  
  async deleteOpLoc(id) {
    const response = await axios.delete(API_URL + id, { headers: authHeader() });
      return response;
  }

}
export default new OpLocService();

