import KeyboardModule from 'diagram-js/lib/features/keyboard';

import { isArray } from 'min-dash';
import ExtendedElements from '../helpers/ExtendedElements';

var PRIORITY = 1000;

/**
 * Adds custom keyboard bindings.
 *
 * This does not pull in any features will bind only actions that
 * have previously been registered against the editorActions component.
 *
 * @param {EventBus} eventBus
 * @param {Keyboard} keyboard
 * @param {Selection} selection
 */
export function CustomKeyboardBindings(eventBus, keyboard, selection) {

  var self = this;

  eventBus.on('editorActions.init', PRIORITY, function (event) {

    var editorActions = event.editorActions;

    self.registerBindings(keyboard, editorActions, selection, eventBus);
  });
}

CustomKeyboardBindings.$inject = [
  'eventBus',
  'keyboard',
  'selection'
];


/**
 * Register available keyboard bindings.
 *
 * @param {Keyboard} keyboard
 * @param {EditorActions} editorActions
 * @param {Selection} selection
 * @param {EventBus} eventBus
 */
CustomKeyboardBindings.prototype.registerBindings = function (keyboard, editorActions, selection, eventBus) {

  /**
   * Add keyboard binding if respective editor action
   * is registered.
   *
   * @param {string} action name
   * @param {Function} fn that implements the key binding
   */
  function addListener(action, fn) {

    if (editorActions.isRegistered(action)) {
      keyboard.addListener(PRIORITY, fn);
    }
  }

  // delete selected element
  // DEL
  // Delete if no referenceDiagram
  // Fire event if referenceDiagram
  addListener('removeSelection', function (context) {

    var event = context.keyEvent;

    if (isKey(['Backspace', 'Delete', 'Del'], event)) {

      const extendedElements = new ExtendedElements(selection.get())

      if (extendedElements.hasReferenceDiagram) {

        eventBus.fire('element.delete.requested', {extendedElements, type: event.type});

      } else {

        editorActions.trigger('removeSelection');

      }

      // indicate we handled the key stroke
      return true;
    }

  });
}

/**
 * Checks if key pressed is one of provided keys.
 *
 * @param {string|Array<string>} keys
 * @param {KeyboardEvent} event
 */
function isKey(keys, event) {
  keys = isArray(keys) ? keys : [keys];

  return keys.indexOf(event.key) !== -1 || keys.indexOf(event.keyCode) !== -1;
}


const exportedObject = {
  __depends__: [
    KeyboardModule
  ],
  __init__: ['CustomkeyboardBindings'],
  CustomkeyboardBindings: ['type', CustomKeyboardBindings]
};

export default exportedObject;