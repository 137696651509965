import React, { useState, useEffect } from 'react';
import AuthService from "../../services/authService";
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Card from "./Card";


function VerifyAccount(props) {
  const [message, setMessage] = useState('');
  const [successful, setSuccessful] = useState(false);

  const params = useParams();
  const token = params.token;

  // useEfect()
  // Call for the firt time only
  useEffect(() => {
    sendVerification()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function sendVerification() {
    console.log(token);
    AuthService.verify(token)
      .then(
        response => {
          setMessage(response.data.message)
          setSuccessful(true);
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(resMessage)
          setSuccessful(false);
          props.navigate("/");
        }
      );
  }

  return (

    <Card>
      {message && (
        <div className="form-group">
          <div
            className={
              successful ? "alert alert-success" : "alert alert-danger"
            }
            role="alert"
          >
            {message}
          </div>
        </div>
      )}
      {successful &&
        <LinkContainer to="/login">
          <button type="submit" className="btn btn-primary btn-block">Login</button>
        </LinkContainer>
      }
    </Card>

  )
}

export default VerifyAccount