import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import AuthService from "../../services/authService";
import Card from "./Card";
import '../../styles/Auth.css'
import { useParams } from 'react-router-dom';


function ResetPassword(props) {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const params = useParams();
  const token = params.token;
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = () => {
    return Yup.object().shape({
      password: Yup.string()
        .test(
          "len",
          "The password must be between 8 and 40 characters.",
          (val) =>
            val &&
            val.toString().length >= 8 &&
            val.toString().length <= 40
        )
        .required("This field is required!")
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&^.|\\/,]).{1,}$$/,
          "Must contain at least one uppercase, one lowercase, one number and one special character"
        ),
    });
  }

  const handleSubmit = (formValue) => {
    const { password } = formValue;
    setMessage('');
    setLoading(true);

    AuthService.reset(token, password)
      .then((user) => {
        props.navigate("/login");

      },
        error => {
          const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          setLoading(false);
          setMessage(resMessage);

        });
  }

  return (
    <Card>

          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            <Form>

              <div className="form-group">
                <label htmlFor="password">New Password</label>
                <Field type="password" className="form-control" name="password" placeholder="" />
                <ErrorMessage name="password" component="div" className="alert alert-danger" />
              </div>


              <hr />
              <div className="d-grid gap-2 col-6 mx-auto">

                <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                  {loading && (
                    <span className="spinner-border spinner-border-sm"> </span>
                  )}
                  <span>Reset Password</span>
                </button>
              </div>

              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}

            </Form>
          </Formik>
          </Card>
  );
}

export default ResetPassword;

