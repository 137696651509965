import { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import PublicHome from './components/PublicHome';
import Modeler from './components/Modeler';
import Error from './components/Error';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Profile from './components/auth/Profile';
import Projects from './components/Projects';
import VerifyAccount from './components/auth/VerifyAccount';
import RecoverPassword from './components/auth/RecoverPassword';
import ResetPassword from './components/auth/ResetPassword';
import PrivateRoute from './services/PrivateRoute';
//import useToken from './hooks/useToken';
import { useNavigate } from 'react-router-dom';
import AuthService from './services/authService';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // permet l'utilisation de bootstrap sur tout le site
import 'bootstrap/dist/js/bootstrap.bundle';



function App() {

  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentUser, setCurrentUser] = useState();


  // useEfect()
  // Call for the firt time only
  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      updateUser(user)
    };
  }, []);


  const updateUser = (user) => {
    setCurrentUser(user);
    if (user) {
      setIsAdmin(user.roles.includes("ROLE_ADMIN"));
    } else {
      AuthService.logout();
      setIsAdmin(false);
    }
  }


  return (

    <Container fluid className="h-100">
      <Row>
        <Header user={currentUser} updateUser={updateUser} />
       
      </Row>
      <Row className="app-content">
        <Routes>
          <Route path="*" element={<Error />} />
          <Route path="/project/:projectId" element={<PrivateRoute><Modeler navigate={navigate} /></PrivateRoute>} >
            <Route path="diagram/:level4DiagramId" element={<Modeler navigate={navigate} />} >
              <Route path=":level3DiagramId" element={<Modeler navigate={navigate} />} >
                <Route path=":level2DiagramId" element={<Modeler navigate={navigate} />} />
              </Route>
            </Route>
          </Route>
          <Route path="/profile" element={<PrivateRoute><Profile updateUser={updateUser}/></PrivateRoute>} />
          {currentUser ? <Route path="/" element={<Projects navigate={navigate} />} />
                        : <Route path="/" element={<PublicHome />} />
          }
          {!currentUser && <Route path="/register" element={<Register />} />}
          <Route path="/login" element={<Login updateUser={updateUser} navigate={navigate} />} />
          <Route path="/verify/:token" element={<VerifyAccount navigate={navigate} />} />
          <Route path="/recover" element={<RecoverPassword />} />
          <Route path="/reset/:token" element={<ResetPassword navigate={navigate}/>} />
        </Routes>
      </Row>
      {!currentUser && isAdmin &&
      <Row>
        <Footer /> 
      </Row>}
    </Container>)
}

export default App
