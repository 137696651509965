import React, { useState } from "react";
import { Form, FormControl, Button } from 'react-bootstrap'
import { PencilFill } from 'react-bootstrap-icons';
import '../styles/EditableSelect.css'

function EditableSelect(props) {

  const [isEdited, setIsEdited] = useState(false);

  function handleValueChange(e) {
    if (e.target.value !== e.target._wrapperState.initialValue) {
      props.onChange(e)
    }
    setIsEdited(false);
  }

  return isEdited ?
    <FormControl
      type="text"
      className="editable-select"
      name="Name"
      autoFocus
      defaultValue={props.values.find(value => value.id === props.value).name}
      onBlur={(e) => { handleValueChange(e) }}
    />

    :

    <div className="d-flex">
      <Form.Select
        className="editable-select"
        aria-label="selector"
        value={props.value}
        onChange={(e) => { props.onSelect(e) }}
      >
        <option disabled value='0'>----  Select ----</option>
        {props.values.map((value) => {
          return <option value={value.id} key={value.id}>{value.name}</option>
        })}
      </Form.Select>
      {props.edit &&
        <Button
          type="button"
          className="editable-select"
          variant="outline-secondary"
          disabled={!(props.values.some(value => value.id === props.value))}
          onClick={() => { setIsEdited(true) }}
        ><PencilFill size={14} />
        </Button>
      }
    </div>

}

export default EditableSelect;