import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import AuthService from "../../services/authService";
import Card from "./Card";
import '../../styles/Auth.css'


function Profile(props) {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [successful, setSuccessful] = useState(false);

  const [passLoading, setPassLoading] = useState(false);
  const [passMessage, setPassMessage] = useState('');
  const [passSuccessful, setPassSuccessful] = useState(false);

  const user = AuthService.getCurrentUser();
  const userInitialValues = { email: user.email, name: user.name };
  const passwordInitialValues = { password: "", newPassword: "" };


  const userValidationSchema = () => {
    return Yup.object().shape({
      name: Yup.string()
        .test(
          "len",
          "The name must contain a maximum of 40 characters.",
          (val) =>
            !val ||
            val.toString().length <= 40
        ),
      email: Yup.string()
        .email("This is not a valid email.")
        .required("This field is required!"),

    });
  }

  const passwordValidationSchema = () => {
    return Yup.object().shape({
      newPassword: Yup.string()
        .test(
          "len",
          "The password must be between 8 and 40 characters.",
          (val) =>
            val &&
            val.toString().length >= 8 &&
            val.toString().length <= 40
        )
        .required("This field is required!")
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&^.|\\/,]).{1,}$$/,
          "Must contain at least one uppercase, one lowercase, one number and one special character"
        ),
      password: Yup.string()
        .required("This field is required!")
    });
  }

  const handleSubmit = (formValue) => {
    const { name, email } = formValue;
    setMessage('');
    setLoading(true);

    AuthService.modify(email, name)
      .then((response) => {
        user.name = name === "" ? null : name;
        user.email = email;
        props.updateUser(user);
        setMessage(response.message)
        setSuccessful(true);
        setLoading(false);
      },
        error => {
          const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          setLoading(false);
          setMessage(resMessage);

        });
  }

  const handlePasswordChange = (formValue, {resetForm}) => {
    const { password, newPassword } = formValue;
    setPassMessage('');
    setPassLoading(true);

    AuthService.passwordChange(password, newPassword)
      .then((response) => {
        setPassMessage(response.message)
        setPassSuccessful(true);
        
      },
        error => {
          const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          setPassMessage(resMessage);
          setPassSuccessful(false);
        });
        setPassLoading(false);
        resetForm();
  }

  return (

    <Card>
      <Formik
        initialValues={userInitialValues}
        validationSchema={userValidationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <Field
              name="name"
              className="form-control"
            />
            <ErrorMessage
              name="name"
              component="div"
              className="alert alert-danger"
            />
          </div>

          <div>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <Field name="email" type="email" className="form-control" />
              <ErrorMessage
                name="email"
                component="div"
                className="alert alert-danger"
              />
            </div>

            <hr />
            <div className="d-grid gap-2 col-6 mx-auto">
              <button type="submit" className="btn btn-primary btn-block">
                {loading && (
                  <span className="spinner-border spinner-border-sm"> </span>
                )}
                <span>Save</span></button>
            </div>
          </div>
          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
        </Form>
      </Formik>

      <Formik initialValues={passwordInitialValues} validationSchema={passwordValidationSchema} onSubmit={handlePasswordChange}>
        <Form>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <Field type="password" className="form-control" name="password" placeholder="" />
            <ErrorMessage name="password" component="div" className="alert alert-danger" />
          </div>

          <div className="form-group">
            <label htmlFor="newPassword">New Password</label>
            <Field type="password" className="form-control" name="newPassword" placeholder="" />
            <ErrorMessage name="newPassword" component="div" className="alert alert-danger" />
          </div>


          <hr />
          <div className="d-grid gap-2 col-6 mx-auto">

            <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
              {passLoading && (
                <span className="spinner-border spinner-border-sm"> </span>
              )}
              <span>Change Password</span>
            </button>
          </div>

          {passMessage && (
            <div className="form-group">
              <div className={
                passSuccessful ? "alert alert-success" : "alert alert-danger"
              } role="alert">
                {passMessage}
              </div>
            </div>
          )}

        </Form>
      </Formik>
    </Card>

  );
}

export default Profile;

