import { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import AuthService from "../../services/authService";
import "../../styles/Auth.css";
import Card from "./Card";

export default class RecoverPassword extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      email: "",
      loading: false,
      message: "",
      successful: false
    };
  }

  validationSchema() {
    return Yup.object().shape({
      email: Yup.string()
        .email("This is not a valid email.")
        .required("This field is required!"),
    });
  }

  handleSubmit(formValue) {
    const { email } = formValue;
    this.setState({
      message: "",
      loading: true
    });

    AuthService.recover(email)
      .then((response) => {
        this.setState({
          message: response.data.message,
          successful: true
        });

      },
        error => {
          const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          this.setState({
            loading: false,
            message: resMessage
          });

        });
  }

  render() {
    const { loading, message, successful } = this.state;
    const initialValues = {
      email: "",
    };

    return (
      <Card>

        <Formik initialValues={initialValues} validationSchema={this.validationSchema} onSubmit={this.handleSubmit}>

          <Form>
            {!successful && (
              <div>
                <div className="form-group">
                  <label htmlFor="username">Email Address</label>
                  <Field type="text" className="form-control" name="email" placeholder="" />
                  <ErrorMessage name="email" component="div" className="alert alert-danger" />
                </div>

                <hr />
                <div className="d-grid gap-2 col-6 mx-auto">

                  <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                    {loading && (
                      <span className="spinner-border spinner-border-sm"> </span>
                    )}
                    <span>Reset Password</span>
                  </button>
                </div>
              </div>
            )}
            {message && (
              <div className="form-group">
                <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                  {this.state.message}
                </div>
              </div>)}

          </Form>
        </Formik>

      </Card>
    );
  }

}