import axios from 'axios';
import authHeader from './auth-header';
const API_URL = process.env.REACT_APP_API_URL;


const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + "auth/login", {
        email,
        password
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(email, password, name = "") {
    return axios.post(API_URL + "auth/signup", {
      email,
      password,
      name
    });
  }

  verify(token) {
    return axios.post(API_URL + "auth/verify", {
      token
    });
  }

  recover(email) {
    return axios.post(API_URL + "auth/recover", {
      email
    });
  }

  reset(token, password) {
    return axios.post(API_URL + "auth/reset", {
      token,
      password
    });
  }

  modify(email, name) {
    const user = this.getCurrentUser();

    return axios
      .put(API_URL + "auth/modify/" + user.id, {
        email,
        name
      }, { headers: authHeader() })
      .then(response => {
        user.email = email;
        user.name = name;
        localStorage.setItem('user', JSON.stringify(user));

        return response.data;
      }
      );
  }

  passwordChange(password, newPassword) {
    const user = this.getCurrentUser();

    return axios
      .put(API_URL + "auth/password/" + user.id, {
        password,
        newPassword
      }, { headers: authHeader() })
      .then(response => {
        return response.data;
      }
      );
  }



  getCurrentUser() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      const decodedJwt = parseJwt(user.token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        this.logout();
        return
      }
      return user
    }
  }

}

export default new AuthService();