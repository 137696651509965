import Modeler from "bpmn-js/lib/Modeler";

var is = require('bpmn-js/lib/util/ModelUtil').is;
const color = {
  3: 'lightblue',
  2: 'lightcoral'
}

/**
 * Extend the modeler with custom functionality for isaFactory.
 * 
 * This class contain function to implement custom isaFactory functionnality.
 * 
 * @param {Object} [options] configuration options to pass to the viewer
 * @param {DOMElement} [options.container] the container to render the viewer in, defaults to body.
 * @param {string|number} [options.width] the width of the viewer
 * @param {string|number} [options.height] the height of the viewer
 * @param {Object} [options.moddleExtensions] extension packages to provide
 * @param {Array<didi.Module>} [options.modules] a list of modules to override the default modules
 * @param {Array<didi.Module>} [options.additionalModules] a list of modules to use with the default modules
 *
 */
export default class ExtendedModeler extends Modeler {

  constructor(options) {
    super(options)

    this.modeling = this.get('modeling');
    this.elementRegistry = this.get('elementRegistry');
    this.canvas = this.get('canvas')
  }

  /**
   * 
   * @param {Object} diagram 
   */
  importDiagram(diagram, parentTask) {
    this.importXML(diagram.xml)
      .then(() => {
       // var zoomedAndScrolledViewbox = this.canvas.viewbox();
        const viewport = (JSON.parse(diagram.viewport));
        var zoomedAndScrolledViewbox = this.canvas.viewbox();
        this.canvas.viewbox({
            x: viewport.x,
            y: viewport.y,
            width: zoomedAndScrolledViewbox.outer.width/viewport.scale,
           height: zoomedAndScrolledViewbox.outer.height/viewport.scale
          });

        const participant = this.elementRegistry.find((element) => {
          return is(element, 'bpmn:Participant');
        })

        const startEvent = this.elementRegistry.find((element) => {
          return is(element, 'bpmn:StartEvent');
        })

        const laneName = this.getLaneName(parentTask);
        if (participant && parentTask && participant.businessObject.name !== laneName) {
          this.modeling.updateProperties(participant, { name: laneName, 'isa95:isInherited': true });
          this.attachTaskToParticipant(participant, parentTask);
        }
        const taskName = parentTask && (parentTask.businessObject.name || parentTask.businessObject.id)
        if (startEvent && parentTask && startEvent.businessObject.name !== taskName) {
          this.modeling.updateProperties(startEvent, { name: taskName, 'isa95:isInherited': true });
        }
      })
      .catch((error) => {
        console.log('fail to import diagram:', error)
      });
  }

  /**
   * 
   * @param {Object} element 
   * @param {Object} diagram 
   */
  attachDiagramToTask(element, diagram) {
    if (is(element, 'bpmn:Task')) {
      // this.modeling.updateProperties(element, { 'isafactory:diagram': diagram.id })
      this.modeling.updateProperties(element, { 'isa95:referenceDiagram': diagram.id })
      this.modeling.setColor(element, { fill: color[diagram.level] });
    }
  }

  /**
   * 
   * @param {Object} element 
   * @param {Object} diagram 
   */
  attachTaskToParticipant(element, task) {
    if (is(element, 'bpmn:Participant')) {
      this.modeling.updateProperties(element, { 'isafactory:task': task.id })
      // !!! Peut-être changer de couleur au besoin.
      //this.modeling.setColor(element, { fill: color[task.level] });
    }
  }

  /**
   * Return the isafactory:task linked to an object of the type bpmn:Participant. 
   * 
   * @param {Object} element of type bpmn:Participant
   * @returns {String} taskId
   */
  getParticipantTask(element) {
    if (is(element, 'bpmn:Participant')) {
      return element.businessObject.$attrs['isafactory:task'];
    }
    return undefined;
  }

  /**
   * Return the isa95:referenceDiagram linked to an object of the type bpmn:Task.
   * 
   * @param {Object} element businessObject of the type bpmn:Task
   * @returns {Number} diagramId
   */
  getReferenceDiagram(element) {
    if (is(element, 'bpmn:Task')) {
      return element.businessObject.referenceDiagram;
    }
    return undefined;
  }

  /**
   * 
   * @param {Object} element 
   */
  removeElement(element) {
    this.modeling.removeElements([element]);
  }

  getLaneName(element) {
    if (element && element.businessObject.lanes && element.businessObject.lanes.length) {
      return element.businessObject.lanes[0].name || element.businessObject.lanes[0].id;
    }
    return undefined;
  }

  /**
   * 
   */
  exportArtifacts() {
    return this.saveXML({ format: true })
      .then(({ xml }) => {
        const diagram = {
          xml: xml,
          viewport: JSON.stringify(this.canvas.viewbox())
        }
        return diagram;
      })
      .catch((err) => {
        console.log('Error happened saving XML: ', err);
        return err;
      })
  }



  getTaskWithDiagram(elements, initialElements) {

    elements.forEach((element) => {
      if (is(element, 'bpmn:SubProcess')) {
        this.getTaskWithDiagram(element.businessObject.flowElements, initialElements)
      }

      if (is(element, 'bpmn:Task') && element.referenceDiagram) {
        initialElements.push(element);
      }
    })
  }
}