/**
 * Manage the genealogy of the selected diagram.
 * 
 * This class contain the ancestor of the selected diagram.
 * 
 * @param {Array[Number]} allowedLevel
 *
 */

export default class Genealogy {
  constructor(genealogy=[], allowedLevel = [2,3,4]) {
    
    this.allowedLevels = allowedLevel;
    genealogy.forEach( element => this['level' + element.level] = element)
  
  }

  /**
   * 
   * @param {Number} level
   * @returns {Object} diagram
   */
  getLevel(level) {
    return this['level' + level];
  }

  /**
   * 
   * @param {Object} diagram 
   */
  setLevel(diagram) {
    if ( !this.allowedLevels.includes(diagram.level) ) {
      throw new Error('level ' + diagram.level + ' is not allowed');
    }

    this['level'+ diagram.level] = { id: diagram.id, name: diagram.name, level: diagram.level, diagramId: diagram.diagramId };
  }

}