import { is } from 'bpmn-js/lib/util/ModelUtil';

export default class ExtendedElements {

  constructor(selectedElements) {
    this.hasReferenceDiagram = false;
    this.elements = [];

    selectedElements.forEach(element => {
      const referenceDiagrams = this._getTaskWithDiagram([element])
      this.elements.push({ element, referenceDiagrams })
      
      if (referenceDiagrams.length > 0) {
        this.hasReferenceDiagram = true
      }

    })

  }

  getElements() {
    const elements = [];
    this.elements.forEach((el) => {
      elements.push(el.element)
    })
    return elements;
  }

  getReferenceDiagrams() {
    const referenceDiagrams = [];
    this.elements.forEach((el) => {
      referenceDiagrams.push(...el.referenceDiagrams)
    })
    return referenceDiagrams;
  }


  _getTaskWithDiagram(elements) {

    const Diagrams = [];
    elements.forEach((element) => {
      const businessObject = element.businessObject ? element.businessObject : element;
      if (is(element, 'bpmn:SubProcess')) {

        Diagrams.push(...this._getTaskWithDiagram(businessObject.flowElements));
      }

      if (is(element, 'bpmn:Task') && businessObject.referenceDiagram) {
        Diagrams.push(businessObject.referenceDiagram);
      }
    })
    return Diagrams
  }

}
