import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import ProjectTile from './ProjectTile';
import { Col, Row, Container, Button, Nav, Spinner } from 'react-bootstrap'
import { ArrowDownShort, ArrowUpShort, Search } from 'react-bootstrap-icons';
import projectService from "../services/project";

//import '../styles/Project.css';


function Project(props) {
  const [projectList, setProjectList] = useState([]);
  const [projectListIsInit, setProjectListIsInit] = useState(false);
  const [sortByName, setSortByName] = useState(0);
  const [sortByDate, setSortByDate] = useState(0);
  const [searchParams] = useSearchParams();


  // useEfect()
  // Call for the firt time only
  useEffect(() => {
    updateProjectList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function updateProjectList() {
    projectService.listProject()
      .then((response) => {
        const search = searchParams.get("search") || "";
        const filteredProject = response.projects.filter(function (el) {
          return el.name.includes(search) ||
            el.description.includes(search);
        });
        setProjectList(filteredProject);
        setProjectListIsInit(true);

      })
      .catch(error => {
        console.log(error)
      })
  }

  function handleDelete(projectId) {
    projectService.deleteProject(projectId)
      .then(() => {
        updateProjectList()
      })
      .catch((error) => {
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        alert(resMessage)
      })
  }

  function newProject() {
    projectService.createProject()
      .then(() => {
        updateProjectList()
      })
      .catch(message => {
        console.log(message);
      })
  }

  const orderByName = () => {
    const direction = sortByName < 1 ? 1 : -1
    setProjectList((projectList) => [...projectList].sort((a, b) => {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return (textA < textB) ? -1 * direction : (textA > textB) ? 1 * direction : 0;
    }))
    setSortByName(direction);
    setSortByDate(0);
  }

  const orderByDate = () => {
    const direction = sortByDate < 1 ? 1 : -1
    setProjectList((projectList) => [...projectList].sort((a, b) => {
      return (a.updatedAt > b.updatedAt) ? -1 * direction : (a.updatedAt < b.updatedAt) ? 1 * direction : 0;
    }))
    setSortByDate(direction);
    setSortByName(0);
  }

  const sortArrow = (direction) => {
    return direction === 0 ? <div /> : direction < 0 ? <ArrowUpShort size={14} /> : <ArrowDownShort size={14} />
  }

  return (
    <div>

      {(projectList.length === 0) ?
        <Container fluid className="d-flex justify-content-center align-items-center project-empty">

          {projectListIsInit ?
            <h2>You have no project yet. create your first project <Button onClick={() => { newProject() }}>New Project</Button></h2>
            :
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          }

        </Container>

        :

        <Container fluid>
          <Row>
            <Col className="d-flex justify-content-end align-items-center">
              <div className="mx-2">
                <form className="me-auto search-form">
                  <div className="d-flex align-items-center">
                    <label className="form-label d-flex mb-3 mx-1" htmlFor="search-field"><Search size={18} color="gray" /></label>
                    <input id="search-field" className="form-control search-field" type="search" name="search" />
                  </div>
                </form>
              </div>
              <div className="project-new">
                <Button onClick={() => { newProject() }}>New Project</Button>
              </div>
            </Col>
          </Row>
          <div style={{ padding: '10px' }}>
            <Row>
              <Col md={2} sm={3} xs={4} className="d-flex justify-content-start align-items-end">
                <Nav.Link variant="link" className="px-0" onClick={() => { orderByName() }}>Name{sortArrow(sortByName)}</Nav.Link>
              </Col>
              <Col className="d-inline-flex justify-content-start align-items-end d-none d-sm-flex">
                <Nav.Link className="px-0 disabled">Description</Nav.Link>
              </Col>
              <Col md={2} sm={2} className="d-inline-flex justify-content-start align-items-end d-none d-sm-flex">
                <Nav.Link className="px-0 disabled">Diagrams</Nav.Link>
              </Col>
              <Col md={4} sm={5} xs={8} className="d-flex justify-content-start align-items-end">
                <Nav.Link variant="link" className="px-0" onClick={() => { orderByDate() }}>Modification{sortArrow(sortByDate)}</Nav.Link>
              </Col>

            </Row>
          </div>

          {projectList.map((projects) => {
            return <div key={projects.id} ><ProjectTile handleDelete={handleDelete} project={projects} navigate={props.navigate} /></div>
          })
          }
        </Container>

      }
    </div>
  )
}

export default Project