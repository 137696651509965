import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Container, Button, Breadcrumb } from 'react-bootstrap'
import { Trash, FileEarmarkPlus, EyeFill, Download } from 'react-bootstrap-icons';
import EditableSelect from "./EditableSelect";
import DeleteModal from "./DeleteModal";
import diagramService from "../services/diagram";
import Genealogy from "../services/genealogy";
import "../styles/DiagramContainer.css";
import { debounce } from 'min-dash';


class DiagramNav extends React.Component {
  constructor(props) {
    super(props);

    //Set initial state
    this.state = {
      diagramList: [],
      genealogy: undefined,
      diagramInfo: { id: 0, name: 'untitled', level: 4, projectId: 0, diagramId: null },
      showDeleteModal: false,
      level: undefined,
      taskToDelete: undefined,
      selectedTask: { element: undefined, name: '' },
      isLoading: false,
      zoom: undefined
    }

    this.savedDiagram = undefined;
    this.projectId = this.props.params.projectId
    this.diagramHasChanged = false;

  }

  /*** REACT functions ***/

  componentDidMount() {

    this.handleRouteChange();
    this.savedDiagram = diagramService.defaultDiagram;
    this.modeler = this.props.modeler;

    // add event listener to catch of the page is unloading
    window.addEventListener('beforeunload', (e) => {
      e.preventDefault();
      return this.componentCleanup()
    });

    // hook on the diagram changes
    this.modeler.on(['commandStack.changed', 'canvas.viewbox.changed'], (event) => this.handleDiagramChange(event));

    // hook on shape changes.
    this.modeler.on('shape.changed', (event) => this.handleElementChange(event, this.state.selectedTask));

    // hook for 'bpmn:Task' clicked or added.
    // The event selection.changed return NULL on a dblClick.
    // For that reason, the event element.dblclick must be use.
    // shape.added is use to be able to set the selectedTask when a new task in created,
    // The shape.added event must be use with state.isLoading to filter loading event.
    this.modeler.on(['element.dblclick', 'shape.added', 'selection.changed'], (event) => this.handleElementClick(event));

    // hook on the event import.done
    this.modeler.on('import.done', (event) => this.handleImportDone());

    // hook on custom event element.delete.requested fired by CustomContextPadProvider.js
    this.modeler.on('element.delete.requested', (event) => this.handleDeleteRequested(event));

  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.params !== prevProps.params) {
      this.handleRouteChange();
    }
  }

  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener('beforeunload', this.componentCleanup); // remove the event handler for normal unmounting
  }

  componentCleanup(e) {
    if (this.diagramHasChanged) {
      this.updateDiagram();
    }
  }

  /*** Handle functions ***/

  handleDelete() {

    if (this.state.taskToDelete) {

      this.state.taskToDelete.elements.forEach(async (el) => {
        try {
          for (const id of el.referenceDiagrams) {
            try {
              await diagramService.deleteDiagram(id)
            }
            catch (error) {
              // ignore error if the diagram does not exists (error 404)
              // because the goal is to delete the diagram anyway
              // return all other error
              if (error.response.status !== 404) {
                throw error;
              }
            }
          }
          // All diagram are deleted, remove element
          this.modeler.removeElement(el.element);
        }
        catch (error) {
          console.log('Unable to delete the diagram', error)
        }
      })
    } else {
      diagramService.deleteDiagram(this.state.diagramInfo.id)
        .then((response) => {
          this.updateDiagramList()
          this.handleDiagramInfoChange({ name: '', id: 0, level: 4, parentId: 0 })
          this.changeRoute(this.state.level)
        })
        .catch(error => {
          console.log('Unable to delete the diagram', error)
        })
    }

    this.setState({
      showDeleteModal: false,
      taskToDelete: undefined,
      selectedTask: { element: undefined }
    });
  }


  handleDeleteClose() {
    this.setState({
      showDeleteModal: false,
      taskToDelete: undefined
    });
  }

  handleDeleteRequested = (event) => {
    this.setState({
      showDeleteModal: true,
      taskToDelete: event.extendedElements
    })
  }

  handleDeleteShow() {
    this.setState({ showDeleteModal: true });
  }

  handleDiagramChange = debounce((event) => {
    if (event.viewbox) {
      if (event.viewbox.scale !== this.savedDiagram.viewport.scale || event.viewbox.y !== this.savedDiagram.viewport.y) {
        this.diagramHasChanged = true;
      }
      this.setState({
        zoom: event.viewbox.scale
      })
    } else {
      this.diagramHasChanged = true;
    }
  }, 500)

  handleDiagramInfoChange(diagram) {
    this.state.genealogy.setLevel(diagram)
    this.setState({
      diagramInfo: {
        id: diagram.id,
        name: diagram.name,
        level: diagram.level,
        parentId: diagram.diagramId
      }
    });
  }

  // Pass the selectedTask in argument to get the selectedTask at the moment the function is called
  // instead of the one after the debounce.
  handleElementChange = debounce((event, selectedTask) => {
    try {
      if (
        event.element.type === 'bpmn:Task' &&
        event.element === selectedTask.element &&
        event.element.businessObject.name !== selectedTask.name) {

        const element = event.element;
        const name = element.businessObject.name || element.id

        if (element && this.modeler.getReferenceDiagram(element)) {
          diagramService.updateDiagramInfo({ id: this.modeler.getReferenceDiagram(element), name: name })
            .then(() => { this.updateDiagramList() })
        }
      }
    } catch (err) {

      console.log('Error happened saving XML: ', err);

    }
  }, 1000);

  handleElementClick(event) {
    const element = event.newSelection && event.newSelection.length ? event.newSelection[0] : event.element
    if (this.state.isLoading === false) {
      if (element && element.type === 'bpmn:Task') {
        // get the fisrt element selected

        this.setState({
          selectedTask: {
            element: element,
            name: element.businessObject.name || element.id
          }
        })
      } else {
        this.setState({ selectedTask: { element: undefined } })
      }
    }
  }

  handleImportDone() {
    this.setState({
      isLoading: false
    })
  }

  handleRouteChange() {

    // Read the diagrams Id for level 4, 3 and 2
    const levels = [
      this.props.params.level2DiagramId,
      this.props.params.level3DiagramId,
      this.props.params.level4DiagramId
    ]

    // Find the last diagramId in the route
    const diagramId = parseInt(levels.find(el => el !== undefined));

    const level = diagramId ? 2 + levels.indexOf(levels.find(el => el !== undefined)) : 4;
    const element = (this.state.selectedTask && this.state.selectedTask.element) || undefined;
    const referenceDiagram = (element && element.businessObject.referenceDiagram) || undefined;

    var parentTask = undefined

    // If the loaded diagram is the same as the one in the selected task, 
    // pass the parentTask to the loadDiagram function
    if (referenceDiagram === diagramId) {
      parentTask = element;
    }

    // Init the genealogy with all the diagrams found
    this.readDiagramList()
      .then(diagrams => {

        const genealogy = diagrams.filter(el => (
          levels.includes(el.id.toString())
        ));

        // Load the diagram
        if (diagramId) {
          this.loadDiagram(diagramId, parentTask)
        }

        this.setState({
          genealogy: new Genealogy(genealogy),
          diagramList: diagrams,
          level,
          selectedTask: { id: undefined }
        });

      })
      .catch(error => { console.log(error) });
  }




  /*** CRUD API functions ***/

  readDiagramList() {
    return new Promise((resolve, reject) => {
      diagramService.listDiagram(this.projectId)
        .then((response) => {
          resolve(response.diagrams)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  updateDiagramList() {
    this.readDiagramList()
      .then((diagrams) => {
        this.setState({ diagramList: diagrams });
      })
      .catch(error => {
        console.log(error)
      })
  }

  // CRUD Functions
  createDiagram() {
    diagramService.createDiagram(this.projectId)
      .then((response) => {
        this.handleDiagramInfoChange(response.diagram);
        this.updateDiagramList();
        this.changeRoute(response.diagram.level + 1, response.diagram.id)
        //this.displayDiagram({ xml: response.diagram.xml, viewport: response.diagram.viewport });
      })
      .catch(error => {
        console.log(error);
      })
  };

  createDiagramFromTask() {
    const name = this.state.selectedTask.name
    const element = this.state.selectedTask.element
    diagramService.createDiagram(this.projectId, name, this.state.level - 1, this.state.diagramInfo.id)
      .then((response) => {
        this.updateDiagramList()
        this.modeler.attachDiagramToTask(element, response.diagram);
        this.updateDiagram();
      })
      .catch(error => {
        console.log(error);
      })
  }

  loadDiagram(diagramId, parentTask) {

    this.setState({
      isLoading: true
    })

    if (this.diagramHasChanged) {
      this.updateDiagram()
    }

    diagramService.readDiagram(diagramId)
      .then((response) => {
        this.handleDiagramInfoChange(response.diagram);
        this.diagramHasChanged = false;
        this.displayDiagram(response.diagram, parentTask)
      })
      .catch(error => {
        console.log(error)
      })
  };

  updateDiagram() {
    this.modeler.exportArtifacts()
      .then((diagram) => {
        diagramService.updateDiagram(diagram, this.state.diagramInfo)
          .then(() => {
            this.diagramHasChanged = false;
            this.savedDiagram = diagram
            this.updateDiagramList();
          })
          .catch(error => {
            console.log(error);
          })
      })
      .catch(error => {
        console.log(error);
      })
  }

  downloadDiagram() {
    this.modeler.exportArtifacts()
      .then((diagram) => {
        var element = document.createElement('a');
        element.setAttribute(
          'href', 'data:application/bpmn20-xml;charset=UTF-8,' + encodeURIComponent(diagram.xml)
        );
        element.setAttribute('download', 'diagram.bpmn');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      })
  }


  /*** Helper functions ***/

  displayDiagram(diagram, parentTask) {
    const viewport = JSON.parse(diagram.viewport);

    this.modeler.importDiagram(diagram, parentTask);
    // copy the diagram into savedDiagram
    this.savedDiagram = { ...diagram };

    this.savedDiagram.viewport = viewport;
    this.setState({
      zoom: viewport.scale
    })
  }

  changeRoute(baseLevel, diagramId) {

    var url = '/project/' + this.projectId +
      '/diagram'

    if (baseLevel <= 4) {
      url = url + '/' + this.state.genealogy.level4.id;
    }

    if (baseLevel < 4) {
      url = url + '/' + this.state.genealogy.level3.id;
    }

    if (diagramId) {
      url = url + '/' + diagramId
    }

    this.props.navigate(url);
  }


  render() {

    const level = this.state.level
    const selectedTask = this.state.selectedTask;
    const taskToDelete = this.state.taskToDelete;

    // Return a filtered diagram list based on the level
    const filteredDiagramList = this.state.diagramList.filter((el) => (
      this.state.diagramInfo && el.level === level &&
      (level === 4 ||
        el.diagramId === (this.state.genealogy.getLevel(level + 1) &&
          this.state.genealogy.getLevel(level + 1).id) || undefined))
    );

    if (!this.state.genealogy) {
      return null
    }

    return (
      <div>
        <DeleteModal
          diagrams={this.state.diagramList}
          show={this.state.showDeleteModal}
          onCancel={() => this.handleDeleteClose()}
          onDelete={() => this.handleDelete()}
          id={
            taskToDelete ?
              taskToDelete.getReferenceDiagrams() :
              this.state.diagramInfo.id}
        />

        <Col>
          <div className={'diagram-menu level' + level}><div />

            <Container fluid>
              <Row>

                <Col xs={10} className=" d-inline-flex px-1">
                  <Breadcrumb>

                    {(level < 4) &&
                      <Breadcrumb.Item
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Level 4"
                        onClick={() => {
                          this.changeRoute(4);
                        }}
                      >{this.state.genealogy.level4.name}
                      </Breadcrumb.Item>
                    }

                    {(level < 3) &&
                      <Breadcrumb.Item
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Level 3"
                        onClick={() => {
                          this.changeRoute(3);
                        }}
                      >{this.state.genealogy.level3.name}
                      </Breadcrumb.Item>
                    }

                    {/* Don't use the react-bootstrap Breadcrumb.Item. Input will no longer accept space*/}
                    <li className="breadcrumb-item">
                      <EditableSelect
                        values={filteredDiagramList}
                        value={this.state.diagramInfo.id}
                        edit={level === 4}
                        onChange={(e) => {
                          diagramService.updateDiagramInfo({ ...this.state.diagramInfo, name: e.target.value })
                            .then(() => {
                              this.handleDiagramInfoChange({ ...this.state.diagramInfo, name: e.target.value })
                              this.updateDiagramList()
                            })
                        }}
                        onSelect={(e) => {
                          this.changeRoute(level + 1, e.target.value);
                          //this.loadDiagram(e.target.value)
                        }}
                      />
                    </li>

                    {selectedTask.element && level > 2 &&
                      <Breadcrumb.Item>
                        {this.modeler.getReferenceDiagram(selectedTask.element) ?
                          <Button
                            type="button"
                            variant="secondary"
                            onClick={() => {
                              this.changeRoute(level, this.modeler.getReferenceDiagram(selectedTask.element))
                            }}
                          >
                            {selectedTask.name} <EyeFill size={14} />
                          </Button>
                          :
                          <Button
                            type="button"
                            variant="secondary"
                            onClick={() => this.createDiagramFromTask()}
                          >
                            {selectedTask.name} <FileEarmarkPlus color="lightgray" size={15} />
                          </Button>
                        }
                      </Breadcrumb.Item>
                    }

                  </Breadcrumb>
                  {!this.state.selectedTask.element && level === 4 &&
                    <div className="d-inline-flex px-1">
                      <div className="px-0">
                        <Button
                          type="button"
                          variant="secondary"
                          onClick={() => this.createDiagram()}
                        >
                          <FileEarmarkPlus color="lightgray" size={15} />
                        </Button>
                      </div>
                      <div className="px-0 delete">
                        <Button
                          className="delete"
                          type="button"
                          variant="secondary"
                          onClick={() => this.handleDeleteShow()}
                        >
                          <Trash size={15} />
                        </Button>
                      </div>
                    </div>}
                </Col>
                <Col md={1} className="d-none d-md-inline pt-2">
                  {this.state.zoom && <h5>{parseInt(this.state.zoom*100)}%</h5>}
                </Col>
                <Col md={1} className="d-none d-md-inline">
                  <Button
                    type="button"
                    title="Download"
                    onClick={() => this.downloadDiagram()}
                  >
                    <Download color="lightgray" size={15} />
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </div>
    );
  }
}

// Access url parameter with react-router-dom v6 in a class component 
// https://stackoverflow.com/questions/69967745/react-router-v6-access-a-url-parameter
const withParams = ComponentWithParams => props => {
  const params = useParams();

  return (
    <ComponentWithParams
      {...props}
      params={params}
    />
  );
};

export default withParams(DiagramNav);