import ExtendedElements from '../helpers/ExtendedElements';

class CustomContextPadProvider {
  constructor(contextPad, rules, modeling, translate) {
    contextPad.registerProvider(this);

    this._rules = rules;
    this._translate = translate;
    this._modeling = modeling;
  }

  getContextPadEntries(element) {
    const translate = this._translate;
    const modeling = this._modeling;

    return function (entries) {

      // (1) delete default entry
      delete entries["delete"];

      // (2) add custom
      entries["delete"] = {
        group: "edit",
        className: "bpmn-icon-trash",
        title: translate("Remove"),
        action: {
          click: function (event) {


            const extendedElements = new ExtendedElements([element])

            if (extendedElements.hasReferenceDiagram) {
              // fire a custom event to request the deletion of the task
              modeling._eventBus.fire('element.delete.requested', { extendedElements, type: event.type });

            } else {

              modeling.removeElements([element]);

            }

          }
        }
      };

      return entries;
    };
  }
}

CustomContextPadProvider.$inject = [
  "contextPad",
  "rules",
  "modeling",
  "translate"
];

const exportedObject = {
  __init__: ["customContextPadProvider"],
  customContextPadProvider: ["type", CustomContextPadProvider]
}

export default exportedObject