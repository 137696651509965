/**
 * A custom rule provider to implement design rules specific to isaFactory
 *
 *
 * @param {EventBus} eventBus
 */
class Isa95ObjectInterface {
  constructor(eventBus) {

    this.eventBus = eventBus;
    this.registerEvents();
  }

  
  handlePropertyAdd(event) {

    // this hook will be implemented in an higher level to manage the addition of a property
    console.log('add clicked', event);
  }


  registerEvents() {
    

    // hook on isa95.property.add
    this.eventBus.on('isa95.property.add', 1000, (event) => this.handlePropertyAdd(event));
   
  };

}

Isa95ObjectInterface.$inject = ['eventBus'];

const exportedObject = {
  __init__: [ 'isa95ObjectInterface' ],
  isa95ObjectInterface: [ 'type', Isa95ObjectInterface ]
};

export default exportedObject;