import React from 'react';
import { BpmnPropertiesPanelModule, BpmnPropertiesProviderModule } from 'bpmn-js-properties-panel';
import Isa95PropertiesProviderModule from 'bpmn-js-isa95-properties-panel/lib/properties-panel/provider/isa95';
import isa95ModdleDescriptor from 'bpmn-js-isa95-properties-panel/lib/properties-panel/descriptors/isa95.json';
import customDescriptor from '../moddle/custom.json';
import customContextPadProviderModule from "../modeler/CustomContextPadProvider";
import CustomKeyboardBinding from "../modeler/CustomKeyboardBinding";
import Modeler from '../modeler/ExtendedModeler';
import DiagramNav from './DiagramNav';
import isa95DataProvider from '../modeler/Isa95CustomDataProvider';
import InterfaceCustomRules from '../modeler/Isa95InterfaceProvider';

// Import vendor's CSS
import '../../node_modules/bpmn-js/dist/assets/diagram-js.css'
import '../../node_modules/bpmn-js/dist/assets/bpmn-js.css'
import '../../node_modules/bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css'
import '../../node_modules/bpmn-js-properties-panel/dist/assets/properties-panel.css'
import '../../node_modules/bpmn-js-isa95-properties-panel/lib/properties-panel/css/isa95.css'
// and custom css
import '../styles/Modeler.css'

export default class ModelerClass extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modeler: undefined,
      selectedTask: { element: undefined, name: '' },
      selectedTaskName: '',
      taskToDelete: undefined,
    };

    this.containerRef = React.createRef();
    this.propertiesPanelRef = React.createRef();
  }

  componentDidMount() {

    const container = this.containerRef.current;
    const propertiesPanel = this.propertiesPanelRef.current;
    const modeler = new Modeler({
      container: container,
      keyboard: {
        bindTo: document
      },
      propertiesPanel: {
        parent: propertiesPanel
      },
      additionalModules: [
        BpmnPropertiesPanelModule,
        BpmnPropertiesProviderModule,
        Isa95PropertiesProviderModule,
        customContextPadProviderModule,
        CustomKeyboardBinding,
        //  Isa95CommandInterceptorModule
        isa95DataProvider,
        InterfaceCustomRules

      ],
      moddleExtensions: {
        isa95: isa95ModdleDescriptor,
        isaFactory: customDescriptor
      }
    });

    this.setState({ modeler: modeler });
  }

  componentWillUnmount() {
    this.state.modeler.destroy();
  }


  render() {
    return (
      <div>
        {this.state.modeler &&
          <DiagramNav
            modeler={this.state.modeler}
            navigate={this.props.navigate}
          />
        }
        <div className="diagram-content">
          <div className="content with-diagram">
            <div
              className="canvas"
              ref={this.containerRef}
            ></div>
            <div className="properties-panel-parent disabled-participant-name"
              ref={this.propertiesPanelRef}></div>
          </div>
        </div>
      </div>
    );
  }
}