import OpLocService from '../services/operationalLocation';

/**
 * Override the base module Isa95DataProvider provided whit the isa95 provider module.
 * 
 * To override the base module this module must be export with the name isa95DataProvider.
 * 
 */

class CustomDataProvider {


  getOperationSegment() {
    return [
      { value: 4, label: 'isafactory segment foo' },
      { value: 3, label: 'isafactory segment bar' },
    ]
  }

  getOperationDefinition() {
    return [
      { value: 1, label: 'isafactory definition foo' },
      { value: 2, label: 'isafactory definition bar' }
    ]
  }

  getProcessSegment() {
    return [
      { value: 1, label: 'isafactory process segment foo' },
      { value: 2, label: 'isafactory process segment bar' }
    ]
  }

  async getArea() {
    try{
    const opLocs = await OpLocService.listOpLoc(1, 1)
    console.log(opLocs.operationalLocations[0])
    const options = opLocs.operationalLocations.map((row) => {
      return { value: row.id, label: row.name }
    })
    console.log(options)
    //return options;
    return [
      { value: 1, label: 'isafactory process segment foo' },
      { value: 2, label: 'isafactory process segment bar' }
    ]
  }
  catch(error){
    console.log(error)
    return []
  }

  }


  createOperationSegment() {

  }
}

const exportedObject = {
  __init__: ['isa95DataProvider'],
  isa95DataProvider: ['type', CustomDataProvider]
};

export default exportedObject;