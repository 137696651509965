import logo from '../../assets/logo-md.png'
import '../../styles/Auth.css'

export default function Card(props) {

  return (
    <div className="profile-background">

      <div className="col-md-12">
        <div className="card card-container">
          <img
            src={logo}
            alt="profile-img"
            className="profile-img-card"
          />
          {props.children}
        </div>
      </div>
    </div>
  );
}


