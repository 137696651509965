import '../styles/Footer.css'
import {Col } from 'react-bootstrap'

function Footer(props) {
    const copyright = 'ISA Factory is powered by Otus innovations inc.'
    return  (
        
        <Col>
            <div>
                <p>{ copyright }<span className="bg-danger" > This project is under active developement</span></p>
            </div>
        </Col>
        )
}

export default Footer