import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL + 'diagram/';

// default diagram and new diagram
const defaultDiagram = {
  xml: 
  `<? xml version="1.0" encoding="UTF-8" ?>
    <bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="di_{{id}}" targetNamespace="http://isafactory.io/" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd">
      <bpmn:collaboration id="Collaboration_034xeru" isa95:level="{{level}}">
        <bpmn:participant id="Participant_017k3ho" name="Site" processRef="Process_1" />
      </bpmn:collaboration>
      <bpmn:process id="Process_1" isExecutable="false">
        <bpmn:laneSet id="LaneSet_0bd51hu">
          <bpmn:lane id="Lane_1pepyi8">
            <bpmn:flowNodeRef>StartEvent_1</bpmn:flowNodeRef>
          </bpmn:lane>
        </bpmn:laneSet>
        <bpmn:startEvent id="StartEvent_1" />
      </bpmn:process>
      <bpmndi:BPMNDiagram id="BPMNDiagram_1">
        <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Collaboration_034xeri">
          <bpmndi:BPMNShape id="Participant_017k3ho_di" bpmnElement="Participant_017k3ho" isHorizontal="true">
            <dc:Bounds x="140" y="65" width="690" height="310" />
          </bpmndi:BPMNShape>
          <bpmndi:BPMNShape id="Lane_1pepyi8_di" bpmnElement="Lane_1pepyi8" isHorizontal="true">
            <dc:Bounds x="170" y="65" width="660" height="310" />
          </bpmndi:BPMNShape>
          <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
            <dc:Bounds x="222" y="207" width="36" height="36" />
            <bpmndi:BPMNLabel>
              <dc:Bounds x="167" y="255" width="47" height="14" />
            </bpmndi:BPMNLabel>
          </bpmndi:BPMNShape>
        </bpmndi:BPMNPlane>
      </bpmndi:BPMNDiagram>\n</bpmn:definitions>`,
  viewport: "{\"x\":0,\"y\":0,\"width\":706,\"height\":743,\"scale\":1,\"inner\":{\"width\":702,\"height\":322,\"x\":84,\"y\":14},\"outer\":{\"width\":706,\"height\":743}}"
}

class DiagramService {

  constructor() {
    this.defaultDiagram = defaultDiagram
  }

  async createDiagram(projectId, name = 'untitled', level = 4, parent=null) {
    this.defaultDiagram.level = level
    const response = await axios.post(API_URL, {
      name: name,
      level: level,
      xml: this.newXml(level, name),
      viewport: defaultDiagram.viewport,
      projectId: projectId,
      diagramId: parent
    }, { headers: authHeader() });
    return response.data;
  }

  async listDiagram(projectId) {
    const response = await axios.get(API_URL, {
      params: { projectId: projectId },
      headers: authHeader()
    });
    return response.data;
  }

  async readDiagram(diagramId) {
    const response = await axios.get(API_URL + diagramId, { headers: authHeader() });
    return response.data;
  }

  async updateDiagram(diagram, diagramInfo) {
    const response = await axios.put(API_URL + diagramInfo.id, {
      name: diagramInfo.name,
      level: diagramInfo.level,
      xml: diagram.xml,
      viewport: diagram.viewport
    }, { headers: authHeader() });
    return response;
  }

  async updateDiagramInfo(diagramInfo) {

    const response = await axios.put(API_URL + diagramInfo.id, {
      ...diagramInfo
    }, { headers: authHeader() });
    return response;
  }

  async deleteDiagram(diagramId) {
      const response = await axios.delete(API_URL + diagramId, { headers: authHeader() });
      return response;
  }

  newXml(level, name) {
    return defaultDiagram.xml.replace("{{level}}", level).replace("{{id}}", Math.random().toString(36).replace("0.",""))
  }

}
export default new DiagramService();

