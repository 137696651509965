import React, { useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap'
import { PencilSquare, Trash } from 'react-bootstrap-icons';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import projectService from "../services/project";
import moment from 'moment';
import DeleteModal from "./DeleteModal";

import '../styles/ProjectTile.css'

function ProjectTile(props) {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState(props.project)
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDelete = () => {
    props.handleDelete(props.project.id);
    setShow(false);
  }

  function validationSchema() {
    return Yup.object().shape({
      name: Yup.string()
        .test(
          "len",
          "The name must be between 3 and 20 characters.",
          (val) =>
            val &&
            val.toString().length >= 3 &&
            val.toString().length <= 20
        )
        .required("This field is required!"),
      description: Yup.string()
        .test(
          "len",
          "The description must be maximim 40 characters.",
          (val) =>
            !val ||
            val.toString().length <= 125
        )
    });
  }

  function handleSave(formValue) {
    setMessage("");
    setLoading(true);

    projectService.updateProject(formValue)
      .then(() => {
        setLoading(false);
        setProject({ ...project, name: formValue.name, description: formValue.description })
      })
      .catch((error) => {
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        setMessage(resMessage);
        setLoading(false);
      })
  }

  const initialValues = { ...props.project, description: props.project.description || '' }
  return (
    <div>

      <DeleteModal
        diagrams={project.diagrams}
        show={show}
        onCancel={handleClose}
        onDelete={handleDelete} />

      <div className="accordion" id={"accordion" + project.id}>
        <div className="accordion-item tile-shadow">
          <h2 className="accordion-header" id="headingOne">
            <Row >
              
              <Col md={2} sm={3} xs={4} className="d-flex align-items-center tile-project-name" onClick={() => props.navigate('project/' + props.project.id)}>
                <h5>{project.name}</h5>
              </Col>

              <Col className="d-flex tile-project-name d-none d-sm-flex align-items-center">
                <h6>{project.description}</h6>
              </Col>

              <Col md={2} sm={2} className="d-flex tile-project-name d-none d-sm-flex align-items-center" onClick={() => console.log(props.project.id)}>
                <h6>{project.diagrams.length}</h6>
              </Col>

              <Col md={2} sm={3} xs={4} className="d-flex align-items-center tile-project-name" onClick={() => console.log(props.project.id)}>
                <h6>{moment(project.updatedAt).calendar()}</h6>
              </Col>
              
              <Col md={2} sm={2} xs={4} className="d-flex justify-content-end align-items-center">
                
                <Button
                  className="mx-1"
                  variant="outline-secondary"
                  disabled={project.memberships[0].type === 'view'}
                  data-bs-toggle="collapse"
                  data-bs-target={'#collapse-' + project.id}
                  aria-expanded="true"
                  aria-controls={'collapse-' + project.id}>
                  <PencilSquare size={15} />
                </Button>

                <Button
                  className="delete"
                  variant="outline-secondary"
                  disabled={!(project.memberships[0].type === 'owner')}
                  onClick={handleShow}
                >
                  <Trash size={15} />
                </Button>

              </Col>
            </Row>

          </h2>
          <div id={'collapse-' + project.id} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent={"#accordion" + project.id}>
            <div className="accordion-body">
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSave}>
                <Form>
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <Field
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="name"
                      disabled={!(project.memberships[0].type === 'owner')}
                    />
                    <ErrorMessage name="name" component="div" className="alert alert-danger" />
                  </div>

                  <div className="form-group">
                    <label htmlFor="description">description</label>
                    <Field type="text" className="form-control" name="description" placeholder="description" />
                    <ErrorMessage name="description" component="div" className="alert alert-danger" />
                  </div>

                  <hr />
                  <Button type="submit" variant="primary" disabled={loading}>
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Save</span>
                  </Button>
                  {message && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        {message}
                      </div>
                    </div>
                  )}
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}

export default ProjectTile;