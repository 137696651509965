import '../styles/Footer.css';
import { Nav } from 'react-bootstrap';
// LinkContainer must be use to have react-router-dom to fire a componentWillUnmount
import { LinkContainer } from 'react-router-bootstrap';
import exampleGeneral from '../assets/Example-General.gif';
import exampleISA95 from '../assets/Example-ISA95.png';
import exampleBpmnio from '../assets/Example-bpmnio.gif';
import exampleVertical from '../assets/Example-Vertical.gif';

function PublicHome(props) {
  return (

    <div className="container-fluid">

      <div className="row landing first">

        <div className="col-md-6 my-auto p-5">
          <h1>
            Create BPMN within ISA-95 Spirit
          </h1>
          <p>
            ISA Factory is an intelligent modeling tool that facilitates mapping of your factory using well-established standards.
          </p>
          <LinkContainer to="/register">
            <Nav.Link><button type="button" className="btn btn-primary">
              Sign up NOW, it's free!
            </button></Nav.Link>
          </LinkContainer>
        </div>

        <div className="col-md-6">
          <img
            src={exampleGeneral}
            alt="Demo"

            className="d-block mx-auto img-fluid"
          />
        </div>

      </div>

      <div className="row flex-column-reverse flex-md-row landing middle">

        <div className="col-md-6">
          <img
            src={exampleISA95}
            alt="ISA-95 properties panel"

            className="d-block mx-auto img-fluid"
          />
        </div>

        <div className="col-md-6 my-auto p-5">
          <h1>
            The acuteness of ISA&#8209;95 within BPMN
          </h1>
          <p>
            The structure of ISA&#8209;95 is directly integrated into the modeling elements of the BPMN&#160;2.0 notation.
          </p>

          <form method="get" action="https://www.isa.org/standards-and-publications/isa-standards/isa-standards-committees/isa95">
            <button type="submit" className="btn btn-primary">
              Find more about ISA-95
            </button>
          </form>
        </div>

      </div>

      <div className="row landing middle">

        <div className="col-md-6 my-auto p-5">
          <h1>
            Process mapping with vertical alignment
          </h1>
          <p>
            The factory process mapping is done globally and uniformly and is therefore aligned at all levels, from management to operations.
          </p>
        </div>

        <div className="col-md-6">
          <img
            src={exampleVertical}
            alt="Operation Segment"

            className="d-block mx-auto img-fluid"
          />
        </div>

      </div>

      <div className="row flex-column-reverse flex-md-row landing middle">

        <div className="col-md-6">
          <img
            src={exampleBpmnio}
            alt="Operation Segment"

            className="d-block mx-auto img-fluid"
          />
        </div>

        <div className="col-md-6 my-auto p-5">
          <h1>
            Great usability of bpmn.io
          </h1>
          <p>
            isafactory.io is built on top of bpmn.io which is an easy to use editor to create BPMN&#160;2.0 diagrams.
          </p>
        </div>

      </div>

      <div className="row landing last">

        <div className="container-fluid">

          <div className="row">

            <div className="col-md-12">
              <h1 className="text-center">
                A comprehensive solution for mapping manufacturing processes
              </h1>
              <p className="text-center">
                isafactory.io is based on two recognized industry standards:
              </p>
            </div>

          </div>

          <div className="row landing middle">

            <div className="col-md-6">

              <div className="landing-card">
                <h2 className="text-center">
                  BPMN&#160;2.0
                </h2>
                <p className="text-center">
                  An easy-to-use and feature-rich modeling standard.
                </p>
              </div>

            </div>

            <div className="col-md-6">

              <div className="landing-card">
                <h2 className="text-center">
                  ISA-95
                </h2>
                <p className="text-center">
                  A complete object model containing all the information necessary to operate a manufacturing facility.
                </p>
              </div>

            </div>

          </div>

        </div>

      </div>

    </div>
  )
}

export default PublicHome